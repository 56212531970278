import React, { useState } from "react";
import { pix } from "../../../constants/LandingPage/cardsImages";
import accordianClose from "../../../assets/images/accordian-close.svg";
import accordianOpen from "../../../assets/images/accordian-open.svg";
import bullet from "../../../assets/images/growthBullet.svg";
import "./CardsSection.css";
import { Link } from "react-router-dom";

function CardsSections({ cardsData }) {
  const [toggleId, setToggleId] = useState("");
  var area = "all";
  var survey = "all";

  const handleToggle = async (id) => {
    if (toggleId === id) setToggleId("");
    else setToggleId(id);
  };

  return (
    <div className="metrices3-grid">
      {cardsData &&
        Object.keys(cardsData)?.map((l1, key) => (
          <div className="metrices3-card" key={key}>
            <div className="metrices3-card-header">
              <div className="icon-wrapper">
                <img className="card-icon" src={pix[l1]} alt="" />
              </div>
              <h3 id={key} className="level1" title={l1}>
                {l1}
              </h3>
            </div>
            <div className="accordion">
              {Object.keys(cardsData[l1])?.map((l2, key2) => {
                return (
                  cardsData[l1][l2].length > 0 && (
                    <div key={key2}>
                      <div className="l2-heading-wrapper pointer">
                        {toggleId === cardsData[l1][l2][0]["id"] ? (
                          <img
                            src={accordianOpen}
                            onClick={() =>
                              handleToggle(cardsData[l1][l2][0]["id"])
                            }
                            alt=""
                            className="accordian-icon"
                          />
                        ) : (
                          <img
                            src={accordianClose}
                            onClick={() =>
                              handleToggle(cardsData[l1][l2][0]["id"])
                            }
                            alt=""
                            className="accordian-icon"
                          />
                        )}
                        <h4
                          className={`accordion-toggle ${
                            toggleId === cardsData[l1][l2][0]["id"]
                              ? " selected-accordion-title"
                              : ""
                          }`}
                          id={key2}
                          onClick={() =>
                            handleToggle(cardsData[l1][l2][0]["id"])
                          }
                        >
                          {l2}
                        </h4>
                      </div>
                      <div
                        className={
                          toggleId === cardsData[l1][l2][0]["id"]
                            ? "accordion-content open-view"
                            : "accordion-content"
                        }
                      >
                        <ul data-link={key2}>
                          {cardsData[l1][l2].map((l3, key3) => {
                            if (
                              (area === "all" && survey === "all") ||
                              (l3.level.indexOf(area) !== -1 &&
                                survey.toLowerCase() === "all") ||
                              (l3.level.indexOf(area) !== -1 &&
                                survey.toLowerCase() ===
                                  l3.source.toLowerCase()) ||
                              (area === "all" &&
                                survey.toLowerCase() ===
                                  l3.source.toLowerCase())
                            ) {
                              return (
                                <div className="l3-wrapper" key={key3}>
                                  <img
                                    className="l3-bullet"
                                    src={bullet}
                                    alt=""
                                  />
                                  <li className="section-links" key={key3}>
                                    <Link
                                      className="searchid l3-para"
                                      data-sfid={l3.id}
                                      to={"/data-portal?sf=" + l3.id}
                                    >
                                      {l3["variable_name"].replace("]", "") +
                                        " (Source: " +
                                        l3["source"] +
                                        ")"}
                                    </Link>

                                    <span className="badge-wrappper">
                                      {l3["level"] &&
                                        l3["level"].map((k) => {
                                          if (k.toLowerCase() !== "india") {
                                            return (
                                              <span
                                                className="card-badge"
                                                key={k}
                                              >
                                                {k.toLowerCase()}
                                              </span>
                                            );
                                          }
                                        })}
                                    </span>
                                  </li>
                                </div>
                              );
                            }
                          })}
                        </ul>
                      </div>
                      <div className="line-separtor"></div>
                    </div>
                  )
                );
              })}
            </div>
          </div>
        ))}
    </div>
  );
}

export default React.memo(CardsSections);
