/* eslint-disable react/prop-types */
import navIcon from "../../assets/images/nav-icon.png";
import growthBullet from "../../assets/images/growthBullet.svg";
import { Menu } from "antd";
import React, { Fragment } from "react";

function transformStructure(input) {
  let output = [];

  for (const category in input) {
    let categoryObj = {
      key: category,
      icon: null,
      children: [],
      label: category,
    };

    for (const subcategory in input[category]) {
      let subcategoryObj = {
        key: subcategory,
        icon: null,
        children: [],
        label: subcategory,
      };

      input[category][subcategory].forEach((item) => {
        let itemObj = {
          key: item.id,
          level: item.level,
          source: item.source,
          subsection_id: item.subsection_id,
          label: item.variable_name,
          icon: <img src={growthBullet} alt="" />,
        };
        subcategoryObj.children.push(itemObj);
      });

      categoryObj.children.push(subcategoryObj);
    }
    output.push(categoryObj);
  }

  return output;
}

function findTargetObject(input, keysArray) {
  let targetObject = null;
  keysArray.forEach((key) => {
    if (targetObject === null) {
      targetObject = input[key];
    } else if (targetObject?.[key]) {
      targetObject = targetObject?.[key];
    } else {
      targetObject = targetObject?.find((obj) => obj.id === Number(key));
    }
  });

  return targetObject;
}

function LeftNavigation({
  sectionList,
  selectedSection,
  handleSelectSections,
}) {
  const onClick = (e, props) => {
    const a = findTargetObject(sectionList, e.keyPath.reverse());
    handleSelectSections(a);
  };

  const items = transformStructure(sectionList);
  return (
    <Fragment>
      {sectionList && (
        <div className="leftNavigationContainer">
          <div className="nav-container nav-indicators">
            <h2 className="secondary-heading">INDICATORS</h2>
            <Menu
              onClick={onClick}
              mode="vertical"
              style={{
                overflow: "auto",
              }}
              items={items}
            />
          </div>
          <div className="nav-container nav-dashboard">
            <h2 className="secondary-heading">MORE DASHBOARDS</h2>
            <a className="nav-links" href={process.env.REACT_APP_ECOMETER_LINK}>
              <p>Ecometer</p>
              <img src={navIcon} alt="" className="nav-icon" />
            </a>
            <a
              className="nav-links"
              href={process.env.REACT_APP_AGRI_MARKET_LINK}
            >
              <p>Agri-Market</p>
              <img src={navIcon} alt="" className="nav-icon" />
            </a>
            <a
              className="nav-links"
              href={process.env.REACT_APP_DAILY_FOOD_PRICES_LINK}
            >
              <p>Daily Food Prices</p>
              <img src={navIcon} alt="" className="nav-icon" />
            </a>
          </div>
        </div>
      )}
    </Fragment>
  );
}

export default React.memo(LeftNavigation);
