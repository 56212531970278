
import * as d3 from 'd3'
import { format } from 'd3-format';

const yAxisTickFormat = number =>
  format('.2s')(number)
    .replace('G', 'B');

export const tickFormatting = (num) => {
    var num_format, temp;
    if (num > 1000) {
      temp = yAxisTickFormat(num);
      if (isNaN(num)) {
        return "NA";
      } else {
        return temp;
      }
    } else {
      num_format = d3.format(".2f");
      if (isNaN(num)) {
        return "NA";
      } else {
        return num_format(num);
      }
    }
}

export const heatmapTickFormatting = (num) => {
  var num_format;
  // if (num < 1.0 && num > -1.0) {
    // num_format = d3.format(".2f");
  // } else {
    num_format = d3.format(".2s");
  // }
  if (isNaN(num)) {
    return "Value not provided";
  } else {
    return num_format(num);
  }
}