import React from "react";
import bullet from "../../../assets/images/growthBullet.svg";
import { pix } from "../../../constants/LandingPage/cardsImages";
import "./SourceCardsSection.css";
import { FullForms } from "../../../constants/Variables";
import { Link } from "react-router-dom";

function SourceCardsSection({ cardsData }) {
  return (
    <div className="metrices3-grid">
      {cardsData &&
        Object.keys(cardsData).map((key, index) => {
          return (
            <div className="metrices3-card source-card" key={index}>
              <div className="source-metrices3-card-header">
                <div className="source-icon-wrapper">
                  <img
                    className="source-card-icon"
                    src={pix[key.toUpperCase()]}
                    alt=""
                  />
                </div>
                <div className="source-header-content">
                  <h3 className="source-level1">{key.toUpperCase()}</h3>
                  <span className="level1-content">
                    {key.toUpperCase() === "CENSUS"
                      ? `Charts related to the data collected during the ${
                          FullForms[key.toUpperCase()]
                        }`
                      : `Charts showing the data from the ${
                          FullForms[key.toUpperCase()]
                        }`}
                  </span>
                </div>
              </div>
              <div className="accordion">
                <ul>
                  {cardsData[key].map((item, ind) => {
                    return (
                      <div className="l3-wrapper" key={ind}>
                        <img className="l3-bullet" src={bullet} alt="" />
                        <li className="section-links">
                          <Link
                            className="searchid l3-para"
                            data-sfid={item.id}
                            to={"/data-portal?sf=" + item.id}
                          >
                            {item["variable_name"] +
                              " (Source: " +
                              item["source"] +
                              ")"}
                          </Link>
                          <span className="badge-wrappper">
                            {item["level"] &&
                              item["level"].map((k, idx) => {
                                if (k.toLowerCase() !== "india") {
                                  return (
                                    <span key={idx} className="card-badge">
                                      {k.toLowerCase()}
                                    </span>
                                  );
                                }
                              })}
                          </span>
                        </li>
                      </div>
                    );
                  })}
                </ul>
              </div>
            </div>
          );
        })}
    </div>
  );
}

export default React.memo(SourceCardsSection);
