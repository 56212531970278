import React, { Fragment, lazy, Suspense } from "react";
import { useEffect, useState } from "react";
import LoadingIcon from "../../assets/icons/LoadingIcon";
import LeftNavigation from "../../components/LeftNavigation/LeftNavigation";
import { fetchSectionsList } from "../../service/sections";
import "./Dashboard.css";
const MainContent = lazy(() => import('../../components/MainContent/MainContent'));

const renderLoader = () => <LoadingIcon width={50} height={50} />;
function Dashboard() {
  const [isLoading, setIsLoading] = useState(false);
  const [sectionList, setSectionList] = useState(null);
  const [selectedSection, setSelectedSection] = useState(null);

  useEffect(() => {
    async function fetchAndStoreSectionList() {
      setIsLoading(true);
      await fetchSectionsList().then((resp) => {
        let currSelectedSection;
        if(resp){
          setIsLoading(false);
          currSelectedSection = resp?.defaultSelectedSection;
          const searchQueryList = window.location.search.split("=");

          if (searchQueryList.length >= 2 && searchQueryList[0] === "?sf") {
            const currVarId = parseInt(searchQueryList[1]);
            if(resp?.sectionList){
            Object.keys(resp?.sectionList).map((key1) => {
                if(resp?.sectionList[key1]){
                  Object.keys(resp?.sectionList[key1]).map((key2) => {
                    resp?.sectionList[key1][key2].map((val) => {
                      if (val["id"] === currVarId) {
                        currSelectedSection = val;
                      }
                    });
                  });
                }
              });
            }
          }
        }
        setSectionList({ ...resp?.sectionList });
        setSelectedSection({ ...currSelectedSection });
      });
    }
    fetchAndStoreSectionList();
  }, []);

  useEffect(() => {
    if (selectedSection !== null) {
      window.history.replaceState(null, "", `?sf=${selectedSection["id"]}`);
    }
  }, [selectedSection]);

  return (
    <Fragment>
      <div className="pageContent">
        {isLoading ? (
          <LoadingIcon width={50} height={50} />
        ) : (
          <Fragment>
            <Suspense fallback={renderLoader()}>
            {sectionList && selectedSection && (
              <LeftNavigation
                sectionList={sectionList}
                selectedSection={selectedSection}
                handleSelectSections={setSelectedSection}
              />
            )}
              {selectedSection && (
                <MainContent
                  selectedSection={selectedSection}
                  sectionList={sectionList}
                />
              )}
            </Suspense>
          </Fragment>
        )}
      </div>
    </Fragment>
  );
}

export default Dashboard;
