export const Constants = {
    "Map": "map",
    "Line": "line",
    "Bar": "bar",
    "District": "district",
    "State": "state",
    "Total": "total",
    "Urban": "urban",
    "Rural": "rural",
}

export const StateViewKeys = {
    "stc": "State Code",
    "stc_name": "State Name",
    "sector": "Sector",
    "unit": "Unit",
}

export const DistrictViewKeys = {
    "dtc": "District Code",
    "dtc_name": "District Name",
    "stc_dtc_name": "State Name",
    "sector": "Sector",
    "unit": "Unit",
}

export const FullForms = {
    "CENSUS": "census in 2011",
    "NFHS": "National Family Health Survey",
    "NSS": "National Sample Survey Office",
    "PLFS": "Periodic Labour Force Survey",
    // "SRS": "Sample Registration System",
    "ASI": "Annual Survey of Industries",
    "NCRB": "National Crime Records Beaureau",
    "RBI": "Reserve Bank of India"
}