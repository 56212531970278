import { Link } from "react-router-dom";
import CedaHeaderIcon from "../../assets/icons/CedaHeaderIcon";
import { useState, useRef } from "react";
import "./CedaHeader.css"
import { dropdownData } from "../../constants/CedaHeader";
import { FaChevronDown} from "react-icons/fa"
import cedaAshokaTenLogo from "../../assets/images/ceda-ashoka-logo.png"

function Dropdown ({title, entries, isLast}) {
    const [isOpen, setIsOpen] = useState(false)
    const dropdownRef = useRef(null);
    
    const handleMouseEnter = () => {
        setIsOpen(true);
    };

    const handleMouseLeave = () => {
        setIsOpen(false);
    };

    const handleDropdownLeave = () => {
        setIsOpen(false);
    };
    
    return (
        <div>
            <button onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    className='button-header'>
                    {title}
                    <span className='toggle-icon'>
                        {<FaChevronDown/>}
                    </span>
            </button>
            {isOpen && 
            <ul 
                ref={dropdownRef}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleDropdownLeave}
                className="dropdown-content">
                    {entries.map((entry, index) => {
                        return (
                            <li key={index} className="dropdown-item">
                                {entry.url ? (
                                    <Link to={entry.url}>{entry.title}</Link>
                                ) : (
                                    <Dropdown title={entry.title} entries={entry.entries} />
                                )}
                            </li>
                        )
                    })}
            </ul>}
        </div>
    )
}

function CedaHeader () {
    return (
        <div className="cedaHeaderContainer">
            <Link to={"/"}>
                <img src={cedaAshokaTenLogo} alt="" style={{width: "220px", height: "75px"}}/>
            </Link>
            <div className="cedaHeaderMenu">
                {dropdownData.map((dropdown, index) => (
                    <Dropdown key={index} 
                              title={dropdown.title} 
                              entries={dropdown.entries}
                              isLast={index === dropdownData.length - 1} />
                ))}
            </div>
        </div>
    )
}

export default CedaHeader;



