import Header from "./components/Header/Header";
import { ToastContainer } from "react-toastify";
import { useRoutes } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.min.css';
import "./App.css";
import { routes } from "./routes/routes";
import alertIcon from "./assets/images/alert-circle.svg";
import { Fragment } from "react";
import CedaHeader from "./components/CedaHeader/CedaHeader";

function App() {
  const routing = useRoutes(routes());

  return (
    <Fragment>
      <div className="custom-overlay"></div>
      <CedaHeader/>
      {/* <Header /> */}
      <ToastContainer
        position="bottom-right"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        icon={({ type }) => {
          if (type === "error") return <img className="comapre-icon" src={alertIcon} alt="" />;
          else return "ℹ️";
      }}
      />
      {routing}
    </Fragment>
  );
}

export default App;
