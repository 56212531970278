import Dashboard from "../pages/Dashboard/Dashboard";
import LandingPage from "../pages/LandingPage/LandingPage";

export const routes = () => [
    {
      path: "",
      element: <LandingPage />
    },
    {
      path: "/data-portal",
      element: <Dashboard />
    },
  ];