import autonomy from "../../assets/landingpage/img/autonomy.svg";
import budget from "../../assets/landingpage/img/budget.svg";
import demographics from "../../assets/landingpage/img/demographics.svg";
import earnings from "../../assets/landingpage/img/earnings.svg";
import education from "../../assets/landingpage/img/education.svg";
import factory from "../../assets/landingpage/img/factory.svg";
import health from "../../assets/landingpage/img/health.svg";
import living_conditions from "../../assets/landingpage/img/living_conditions.jpg";
import media from "../../assets/landingpage/img/media.png";
import raw from "../../assets/landingpage/img/raw.svg";
import socialattitudes from "../../assets/landingpage/img/socialattitudes.jpg";
import status from "../../assets/landingpage/img/status.png";
import worker from "../../assets/landingpage/img/worker.png";
import CENSUS from "../../assets/images/census.svg";
import RBI from "../../assets/images/RBI.svg";
import NFHS from "../../assets/images/NFHS.svg";
import ASI from "../../assets/images/ASI.svg";
import NCRB from "../../assets/images/NCRB.svg";
import NSS from "../../assets/images/NSS.svg";
import PLFS from "../../assets/images/PLFS.svg";
import SRS from "../../assets/images/SRS.svg";

export const pix = {
  Budget: budget,
  Demographics: demographics,
  Education: education,
  Health: health,
  "Labour & Employment": worker,
  "Social Attitudes": socialattitudes,
  "Well-being": living_conditions,
  "Socio-Economic Status (SES)": status,
  Autonomy: autonomy,
  Media: media,
  "Earnings and Wages": earnings,
  "Assets and Liabilities": factory,
  "Inputs and Outputs": raw,
  CENSUS: CENSUS,
  NFHS: NFHS,
  NSS: NSS,
  PLFS: PLFS,
  SRS: SRS,
  ASI: ASI,
  NCRB: NCRB,
  RBI: RBI,
};
