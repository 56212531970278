export const dropdownData = [
    {
        title: 'Themes',
        entries: [
            { title: 'Work and Wages', url: 'https://ceda.ashoka.edu.in/themes/work-and-wages/' },
            { title: 'Agrarian Economy', url: 'https://ceda.ashoka.edu.in/themes/agrarian-economy/' },
            { title: 'Human Development', url: 'https://ceda.ashoka.edu.in/themes/human-development/' },
            { title: 'Public Finance', url: 'https://ceda.ashoka.edu.in/themes/public-finance/' },
            { title: 'Household Economics', url: 'https://ceda.ashoka.edu.in/themes/household-economics/' },
            { title: 'Data Notes', url: 'https://ceda.ashoka.edu.in/themes/data-notes/' }
        ]
    },
    {
        title: 'Data Portal',
        entries: [
            { title: 'Socio-Economic Data Tool', url: 'https://sedp.ceda.ashoka.edu.in/' },
            { title: 'Agri Market Data Tool', url: 'https://agmarknet.ceda.ashoka.edu.in/' },
            { title: 'Daily Food Prices Data Tool', url: 'https://dca.ceda.ashoka.edu.in/' },
            { title: 'EcoMeter', url: 'https://ecometer.ceda.ashoka.edu.in/daily-food-price-index' },
        ]
    },
    {
        title: 'Initiatives',
        entries: [
            { title: 'Women and Work', url: 'https://ceda.ashoka.edu.in/women-and-work/' }
        ]
    },
    {
        title: 'About Us',
        entries: [
            { title: 'People', url: 'https://ceda.ashoka.edu.in/team/' },
            { title: 'In the News', url: 'https://ceda.ashoka.edu.in/in-the-news/' },
            { title: 'Careers', url: 'https://ceda.ashoka.edu.in/careers/' },
            { title: 'Contact', url: 'https://ceda.ashoka.edu.in/contact-us/' },
            { title: 'Republishing Guidelines', url: 'https://ceda.ashoka.edu.in/republish-policy/' }
        ]
    }
];