import React, { useCallback, useEffect } from "react";
import "../../MainContent/MainContent.css";
import "./ShareableMap.css";
import ceda from "../../../assets/images/ceda.png";
import { fillSVG } from "../../../service/parseSVG";
import { tickFormatting } from "../../../constants/Format";

const ShareableMap = React.forwardRef((props, ref) => {

  const fetchSVG = useCallback(async () => {
    await fillSVG({data: props.data, checkVal: props.topArr[0]["value"]*0.7, splitJK: props.splitJK, unSplitJK: props.unSplitJK, unSplitJKTA: props.unSplitJKTA})
    .then((resp) => {
      document.getElementById(`twitter-map${props.compareFlag && "-compare"}`).appendChild(resp);
      props.setReadyToDownload(true);
    })
  }, [props.data]);

  useEffect(() => {
    if(props.data)  fetchSVG();
  }, [fetchSVG]);

  return (
    <div id={`map-whole-container${props.compareFlag && "-compare"}`} className="twitter-post" ref={ref} style={{
      position: 'absolute',
      top: '-9999px',
      left: '-9999px',
      visibility: 'visible'
    }}>
      <div className="heading_view_wrapper">
        <div className="chartSectionHeader">
          <h2 className="inital-props.visualization-heading">
            {props.chartHeading}&nbsp;(Unit: {props.chartUnit})&nbsp;(Year: {props.selectedYear})
          </h2>
        </div>
        <div id={`filter-info${props.compareFlag && "-compare"}`}>
          <div className="filter-info-text">
            {
              props.filterInfo.map((info, i) => {
                return <p>
                {info}
                {i !== props.filterInfo.length - 1 && (
                  <span>,</span>
                )}
              </p>
              })
            }
          </div>
        </div>
      </div>
      <div className="twitter-post-mid">
        <div className="twitter-boxes">
          <div className="states-box top-states">
            <h3>Top 3 States</h3>
            {
              props.topArr.map((arr, idx) => {
                return <div className="state-details">
                <div className="state-sq top-sq" style={{backgroundColor: props.data[arr["stc"]].color}}></div>
                <div className="state-detail">
                  <span className="state-name">{props.statenames[arr["stc"]]}</span>
                  <span className="state-dot">&#8226;</span>
                  <span className="state-value">{tickFormatting((parseFloat(arr["value"]).toFixed(2)))}
                     </span>
                </div>
              </div>
              })
            }
          </div>

          <div className="states-box bottom-states">
            <h3>Bottom 3 States</h3>
            {
              props.bottomArr.slice().reverse().map((arr, idx) => {
                return <div className="state-details">
                <div className="state-sq top-sq" style={{backgroundColor: props.data[arr["stc"]].color}}></div>
                <div className="state-detail">
                  <span className="state-name">{props.statenames[arr["stc"]]}</span>
                  <span className="state-dot">&#8226;</span>
                  <span className="state-value">{tickFormatting(parseFloat(arr["value"]).toFixed(2))}</span>
                </div>
              </div>
              })
            }
          </div>

          <div className="states-box median-avg-box">
            <h3>Median State</h3>
            <div className="state-details">
              <div className="state-sq top-sq" style={{backgroundColor: props.data[props.medianValue["stc"]].color}}></div>
              <div className="state-detail">
                <span className="state-name">{props.statenames[props.medianValue["stc"]]}</span>
                <span className="state-dot">&#8226;</span>
                <span className="state-value">{tickFormatting(parseFloat(props.medianValue["value"]).toFixed(2))}</span>
              </div>
            </div>
            <div className="line-separtor"></div>
            <h3>Indian Average</h3>
            <div className="state-details-india">
              <div className="state-detail">
                <span className="state-name">India</span>
                <span className="state-dot">&#8226;</span>
                <span className="state-value">{tickFormatting(parseFloat(props.indiaValue).toFixed(2))}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="twitter-map" id={`twitter-map${props.compareFlag && "-compare"}`}>
          {/* <div className="twitter-heatmap legend-container">
            <HeatMap
              heatMapData={props.heatMapData}
              filterStateMapLegend={props.filterStateMapLegend}
              legendText={props.legendText}
              setbinRange={props.setbinRange}
              binRange={props.binRange}
              twidth={props.twidth}
              downloadFlag={false}
              setSvgFlag={props.setSvgFlag}
            />
          </div> */}
        </div>
      </div>
      <div className="twitter-post-bottom">
        <img src={ceda} alt="" /> 
        <p className="pointer">
          https://ceda.ashoka.edu.in/
        </p>
      </div>
    </div>
  );
});

export default ShareableMap;
