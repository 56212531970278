import ReactDOM from "react-dom/client";
import React from 'react';
import App from "./App.js";
import { BrowserRouter } from 'react-router-dom';
import { ConfigProvider } from 'antd';

ReactDOM.createRoot(document.getElementById("root")).render(
    <BrowserRouter>
    <ConfigProvider>
      <App />
      </ConfigProvider>
    </BrowserRouter>
);
