import React, { Fragment, useEffect, useState } from "react";
import { fetchSectionsList } from "../../service/sections";
import search from "../../assets/images/search.svg";
import heroImg from "../../assets/images/heroImg.png";
import CardsSection from "./CardsSection/CardsSection.js";
import SourceCardsSection from "./SourceCardsSection/SourceCardsSection.js";
import "./LandingPage.css";
import _ from "lodash";
import { CreateErrorNotification } from "../../components/common/Notifications/Notification.js";
import bullet from "../../assets/images/growthBullet.svg";
import { Link } from "react-router-dom";
import ShareableMap from "../../components/Charts/Map/ShareableMap.js";

function LandingPage() {
  const [allCards, setAllCards] = useState(null);
  const [cardsData, setCardsData] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [initialResults, setInitialResults] = useState([]);
  const [groupedCardsData, setGroupedCardsData] = useState(null);
  const [type, setType] = useState("India");

  useEffect(() => {
    fetchSections();
  }, []);

  useEffect(() => {
    if (searchResults.length > 0) {
      document.querySelector(".custom-overlay").style.display = "inline";
    } else {
      document.querySelector(".custom-overlay").style.display = "none";
    }
  }, [searchResults]);

  useEffect(() => {
    if (allCards) filterSections();
    // eslint-disable-next-line
  }, [type]);

  useEffect(() => {
    getSearchResults();
    // eslint-disable-next-line
  }, [searchText]);

  const fetchSections = async () => {
    await fetchSectionsList()
      .then(async (resp) => {
        let groupedData = {
          Census: [],
          NFHS: [],
          PLFS: [],
          ASI: [],
          NCRB: [],
          RBI: [],
        };
        let initialTemp = [];
        Object.keys(resp.sectionList).map(async (key1) => {
          Object.keys(resp.sectionList[key1]).map(async (key2) => {
            let temp = resp.sectionList[key1][key2];
            await temp.forEach((item) => {
              item[
                "img"
              ] = `../../../assets/images/${item.source.toUpperCase()}.svg`;
              groupedData[item.source]?.push(item);
              initialTemp.push(item);
            });
          });
        });
        setInitialResults(initialTemp);
        setGroupedCardsData(groupedData);
        setCardsData(resp.sectionList);
        setAllCards(resp.sectionList);
      })
      .catch((err) => CreateErrorNotification(err));
  };

  const filterSections = async () => {
    let temp = _.cloneDeep(allCards);
    Object.keys(allCards).forEach((l1) =>
      Object.keys(allCards[l1]).forEach(async (l2) => {
        temp[l1][l2] = [];
        await allCards[l1][l2]?.forEach((val) => {
          if (val["level"]?.indexOf(type) >= 0) {
            temp[l1][l2].push(val);
          }
        });
      })
    );
    setCardsData(temp);
  };

  const getSearchResults = async () => {
    let str = searchText;
    if (str !== "" && str !== " ") {
      let newarr = await initialResults
        .filter((result) =>
          result["variable_name"].toLowerCase().includes(str.toLowerCase())
        )
        .map((item) => {
          let newItem = item["variable_name"].replace(
            new RegExp(str, "gi"),
            (match) =>
              `<span style="font-weight: 600; background-color: none">${match}</span>`
          );
          return { ...item, variable_name: newItem };
        });
      setSearchResults(newarr);
    } else {
      setSearchResults([]);
    }
  };

  return (
    <section className="landing-screen">
      <div className="hero-section">
        <div className="hero-left">
          <div className="hero-content">
            <h1>
              India's Economy, <span>visualized</span>
            </h1>
            <p>
              SED Dashboard provides you the capability of seeing the not just
              in a table format, but also visualised on maps and charts, making
              it easy for you to check and compare it with other data of the
              same kind.
            </p>
          </div>

          <div className="feature-charts">
            <h5 className="secondary-heading">FEATURED CHARTS</h5>
            <div className="features-wrapper">
              <Link
                className="feature-card feature-card-badge"
                to="/data-portal?sf=1008"
              >
                <span className="f-badge">Most Viewed</span>
                <p>
                  Labour Force Participation Rate (All ages/ Working age) by
                  Gender, Caste, Religion and Activity Status (Source: PLFS)
                </p>
              </Link>

              <Link className="feature-card" to="/data-portal?sf=871">
                <p>
                  Respondent's contraceptive use and intention, by sector and
                  religion (Source: NFHS)
                </p>
              </Link>

              <Link className="feature-card" to="/data-portal?sf=994">
                <p>
                  Number of personnel associated with the factory units (Source:
                  ASI)
                </p>
              </Link>
            </div>
          </div>
        </div>

        <img className="hero-right" src={heroImg} alt="" />
      </div>

      <div className="cards-section">
        <Fragment>
          <div className="cards-category">
            <h4>ALL CATEGORIES</h4>
            <div className="cards-filter">
              <span>Filter by Region:</span>
              <button
                className={
                  "btn-category btn-" +
                  (type === "India" ? "selected" : "unselected")
                }
                onClick={() => setType("India")}
              >
                All
              </button>
              <button
                className={
                  "btn-category btn-" +
                  (type === "District" ? "selected" : "unselected")
                }
                onClick={() => setType("District")}
              >
                District
              </button>
              <button
                className={
                  "btn-category btn-" +
                  (type === "State" ? "selected" : "unselected")
                }
                onClick={() => setType("State")}
              >
                State
              </button>
            </div>
          </div>

          <div className="search-landing-page">
            <div className="search">
              <div className="search-wrapper">
                <img src={search} alt="" className="search-icon" />
                <input
                  className="search-landing-field"
                  type="text"
                  value={searchText}
                  placeholder="Search chart by title Eg: Inventory of the factory units"
                  onChange={(e) => setSearchText(e.target.value)}
                />
              </div>
              <p
                className={`search-clear pointer ${
                  searchResults.length === 0 ? "disabled-clear" : ""
                }`}
                onClick={() => {
                  setSearchText("");
                  setSearchResults([]);
                }}
              >
                CLEAR
              </p>
            </div>

            {searchResults.length > 0 && (
              <div className="search-values-wrapper">
                <span className="search-heading">Search Results</span>
                {searchResults.map((result, idx) => (
                  <Link
                    className="search-entity"
                    key={idx}
                    to={`/data-portal?sf=${result["id"]}`}
                  >
                    <div className="search-value">
                      <img
                        className="l3-bullet search-bullet"
                        src={bullet}
                        alt=""
                      />
                      <p
                        className="search-items pointer"
                        dangerouslySetInnerHTML={{
                          __html: result["variable_name"],
                        }}
                      ></p>
                    </div>
                  </Link>
                ))}
              </div>
            )}
          </div>
        </Fragment>

        <form id="frmSearch" action="index.php" method="POST">
          <input type="hidden" name="txtSearchId" id="txtSearchId" />
        </form>
        {cardsData && <CardsSection cardsData={cardsData} />}
      </div>

      <div className="line-separtor"></div>

      <div className="cards-section">
        <h4 className="sub-heading">BROWSE CHARTS BY SOURCE</h4>
        <SourceCardsSection cardsData={groupedCardsData} />
      </div>
    </section>
  );
}

export default LandingPage;
