/* eslint-disable react/prop-types */
function CloseIcon({ width = 16, height = 14, onClick }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
      style={{ cursor: "pointer" }}
    >
      <path
        d="M7.99979 6.04269L2.25591 0.777459C1.96752 0.513105 1.49995 0.513105 1.21157 0.77746C0.923178 1.04181 0.923179 1.47042 1.21157 1.73477L6.95545 7L1.21157 12.2652C0.923178 12.5296 0.923178 12.9582 1.21157 13.2225C1.49995 13.4869 1.96752 13.4869 2.25591 13.2225L7.99979 7.95731L13.7437 13.2225C14.0321 13.4869 14.4996 13.4869 14.788 13.2225C15.0764 12.9582 15.0764 12.5296 14.788 12.2652L9.04413 7L14.788 1.73477C15.0764 1.47042 15.0764 1.04181 14.788 0.77746C14.4996 0.513105 14.0321 0.513105 13.7437 0.77746L7.99979 6.04269Z"
        fill="#1A375F"
      />
    </svg>
  );
}

export default CloseIcon;
