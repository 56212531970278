import axios from "axios";
import { Constants } from "../constants/Variables";
import { CreateErrorNotification } from "../components/common/Notifications/Notification";

export const fetchSectionsList = async () => {
    try {
      const res = await axios.get(
        process.env.REACT_APP_SECTIONS_AND_VARIABLES_ENDPOINT
      );
      let sectionList = res.data.output.data;
      const defaultSelectedSection = Object.keys(Object.keys(sectionList)[0])
      return {
        "sectionList": sectionList,
        "defaultSelectedSection": defaultSelectedSection
      };
    } catch (error) {
      CreateErrorNotification(error);
    }
};

export const fetchFiltersList = async (id) => {
    try {
        var data = JSON.stringify({
            "id": id
        });
        var config = {
            method: 'post',
            url: process.env.REACT_APP_FILTERS_ENDPOINT,
            headers: { 
              'Content-Type': 'application/json'
            },
            data : data
          };
          
          const res = await axios(config)
        return res.data.data;
    } catch (error) {
      CreateErrorNotification(error);
    }
};

export const fetchSource = async (source, year) => {
  try {
    year = year.toString() + "-01-01"
    var data = JSON.stringify({
        "source": source,
        "date": year
    });
    var config = {
        method: 'post',
        url: process.env.REACT_APP_VARIABLE_SOURCE_ENDPOINT,
        headers: { 
          'Content-Type': 'application/json'
        },
        data : data
      };
      
      const res = await axios(config)
    return res.data.output.data[0];
  } catch (error) {
    CreateErrorNotification(error);
  }
};

export const fetchVariableData = async (visualization, id, facet, year, allFacets, areaType, firstFetchFlag) => {
  const fetchData = async (visualization, id, facet, year) => {
    try {
      var data, config;
      if(visualization===Constants.Line){
        data = JSON.stringify({
          "id": id,
          "vid": facet.vid,
          "sector": areaType
        });
        config = {
          method: 'post',
          url: process.env.REACT_APP_VARIABLE_DATA_ALL_YEAR_ENDPOINT,
          headers: { 
            'Content-Type': 'application/json'
          },
          data : data
        };
          
        const res = await axios(config)
        return res.data.output;
      }
      else{
        year = year.toString() + "-01-01"
        data = JSON.stringify({
          "id": id,
          "vid": facet.vid,
          "date": year,
          "sector": areaType
        });
        config = {
          method: 'post',
          url: process.env.REACT_APP_VARIABLE_DATA_SINGLE_YEAR_ENDPOINT,
          headers: { 
            'Content-Type': 'application/json'
          },
          data : data
        };
          
        const res = await axios(config)
        return res.data.output;
      }
    } catch (error) {
      CreateErrorNotification(error);
    }
  }
  let resp;
  if(firstFetchFlag){
    let i;
    for(i=0;i<allFacets.length;i++){
      resp = await fetchData(visualization, id, allFacets[i], year);
      if(resp?.data.length > 0){
        resp["facet"] = allFacets[i];
        return resp;
      }
    }
  }
  else{
    resp = await fetchData(visualization, id, facet, year);
    resp["facet"] = facet;
  }
  return resp;
};

export const fetchFacetNote = async (facet) => {
  try {
    var data = JSON.stringify({
      "vid": facet.vid,
    });
    var config = {
      method: 'post',
      url: process.env.REACT_APP_FACET_NOTES_API,
      headers: { 
        'Content-Type': 'application/json'
      },
      data : data
    };
      
    const res = await axios(config)
    return res.data.output;
  } catch (error) {
    CreateErrorNotification(error);
  }
}